import React from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import Recaptcha from 'react-recaptcha';
import Box from 'reusecore/src/elements/Box';
import Text from 'reusecore/src/elements/Text';
import Heading from 'reusecore/src/elements/Heading';
import Button from 'reusecore/src/elements/Button';
import Input from 'reusecore/src/elements/Input';
import ErrorMessageComponent from 'common/source/containers/ContactView/ContactForm/errorMessage';
import SuccessMessageComponent from 'common/source/containers/ContactView/ContactForm/successMessage';
import FeatureBlock from '../../components/FeatureBlock';
import Container from '../../components/UI/Container';

import postSubscribe from './post';
import ContactFromWrapper, { SectionMainWrapper } from './contact.style';

import config from '../../../../data/SiteConfig';

const initialValues = {
  email: ''
};

const validateFunc = values => {
  const errors = {};
  if (!values.email) {
    errors.email = '*Ingrese su dirección de correo';
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
    errors.email = '*Dirección de correo no es válida';
  }
  return errors;
};

class ContactSection extends React.Component {
  recaptchaInstance = null;

  state = {
    isVerified: false,
    successMessage: ''
  };

  onSubmit = async (values, { setSubmitting, resetForm }) => {
    setSubmitting(true);
    // setTimeout(() => {
    //   alert(JSON.stringify(values, null, 2));
    //   setSubmitting(false);
    // }, 400);
    const response = await postSubscribe(values);
    if (response.status === 200) {
      this.recaptchaInstance.reset();
      resetForm();
      await this.setState({
        successMessage: '¡Email registrado exitosamente!',
        isVerified: false
      });
    }
    setSubmitting(false);
  };

  recaptchaLoaded = () => {
    console.log('reCaptcha Loaded...');
  };

  onVerifyCallback = response => {
    console.log('veifying captcha');
    if (response) {
      this.setState({ isVerified: true });
    }
  };
  onExpireCallback = () => {
    this.setState({ isVerified: false });
  };

  render() {
    const {
      sectionWrapper,
      row,
      contactForm,
      secTitleWrapper,
      secHeading,
      secText,
      button,
      title,
      description
    } = this.props;
    const { isVerified, successMessage } = this.state;
    return (
      <SectionMainWrapper>
        <Box {...sectionWrapper}>
          <Container className="containerClass">
            <Box {...secTitleWrapper}>
              <FeatureBlock
                title={<Heading {...title} />}
                description={<Text {...description} />}
              />
            </Box>
            <Box {...row}>
              <Box {...contactForm}>
                <ContactFromWrapper>
                  <Formik
                    initialValues={initialValues}
                    validate={validateFunc}
                    onSubmit={this.onSubmit}
                  >
                    {props => {
                      const {
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting
                      } = props;
                      return (
                        <form onSubmit={handleSubmit}>
                          <Input
                            inputType="email"
                            name="email"
                            placeholder="Ingresa tu dirección de correo"
                            iconPosition="right"
                            isMaterial={false}
                            className="email_input"
                            aria-label="Ingresa tu email"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.email}
                            withError={errors.email && touched.email}
                          />
                          {errors.email && touched.email && (
                            <ErrorMessageComponent error={errors.email} />
                          )}
                          <Button
                            {...button}
                            type="submit"
                            title={isSubmitting ? 'Enviando...' : 'SUSCRIBETE'}
                            disabled={isSubmitting || !isVerified}
                            // colors={
                            //   isSubmitting || !isVerified ? 'disabledWithBg' : 'primaryWithBg'
                            // }
                          />
                          <Box width="100%" flexBox justifyContent="center">
                            {successMessage.length > 0 && (
                              <SuccessMessageComponent message={successMessage} />
                            )}
                          </Box>
                          <Box width="100%" p="20px 15px" flexBox justifyContent="center">
                            <Recaptcha
                              ref={e => (this.recaptchaInstance = e)}
                              sitekey={config.googleReCaptchaSiteKey}
                              render="explicit"
                              onloadCallback={this.recaptchaLoaded}
                              verifyCallback={this.onVerifyCallback}
                              expiredCallback={this.onExpireCallback}
                            />
                          </Box>
                        </form>
                      );
                    }}
                  </Formik>
                </ContactFromWrapper>
              </Box>
            </Box>
          </Container>
        </Box>
      </SectionMainWrapper>
    );
  }
}

ContactSection.propTypes = {
  sectionWrapper: PropTypes.object,
  secTitleWrapper: PropTypes.object,
  row: PropTypes.object,
  contactForm: PropTypes.object,
  secHeading: PropTypes.object,
  secText: PropTypes.object,
  button: PropTypes.object,
  // note: PropTypes.object,
  title: PropTypes.object,
  description: PropTypes.object
  // colornote: PropTypes.object
};

ContactSection.defaultProps = {
  sectionWrapper: {
    id: 'contact_section',
    as: 'section',
    pt: ['8px', '80px', '80px', '80px'],
    pb: ['0', '80px', '80px', '80px', '80px']
  },
  secTitleWrapper: {
    mb: ['40px', '40px', '40px'],
    p: ['0 15px', 0, 0, 0, 0]
  },
  secText: {
    as: 'span',
    display: 'block',
    textAlign: 'center',
    fontSize: `${2}`,
    letterSpacing: '0.15em',
    fontWeight: `${6}`,
    color: 'primary',
    mb: `${3}`
  },
  secHeading: {
    textAlign: 'center',
    fontSize: [`${6}`, `${8}`],
    fontWeight: '400',
    color: 'headingColor',
    letterSpacing: '-0.025em',
    mb: `${0}`
  },
  row: {
    flexBox: true,
    justifyContent: 'center'
  },
  contactForm: {
    width: [1, 1, 1, 1 / 2]
  },
  button: {
    type: 'button',
    fontSize: `${2}`,
    fontWeight: '600',
    borderRadius: '4px',
    pl: '22px',
    pr: '22px',
    colors: 'primaryWithBg',
    height: `${4}`
  },
  // note: {
  //   fontSize: '16px',
  //   fontWeight: '400',
  //   color: '#525f7f',
  //   lineHeight: '28px',
  //   mb: ['25px', '25px', '30px', '30px', '45px'],
  //   textAlign: ['center', 'center']
  // },
  // colornote: {
  //   fontSize: '16px',
  //   fontWeight: '500',
  //   color: 'rgb(106, 82, 253)',
  //   lineHeight: '28px',
  //   mb: ['25px', '25px', '30px', '30px', '45px'],
  //   textAlign: ['center', 'center']
  // },
  title: {
    content: 'Mantente al día con nuestras últimas noticias',
    fontSize: ['20px', '26px', '30px', '36px', '40px'],
    lineHeight: ['30px', '32px', '40px', '50px', '55px'],
    fontWeight: '700',
    color: 'lightBlue',
    letterSpacing: '-0.010em',
    mb: '20px',
    textAlign: ['center', 'center']
  },
  description: {
    content: 'Se el primero en enterarte de nuevas soluciones y productos.',
    fontSize: '16px',
    fontWeight: '400',
    color: 'labelColor',
    lineHeight: '28px',
    mb: ['25px', '25px', '30px', '30px', '45px'],
    textAlign: ['center', 'center']
  }
};

export default ContactSection;
