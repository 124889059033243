import styled from 'styled-components';

const BannerPic =
  'https://res.cloudinary.com/chamlabs/image/upload/c_fill,g_auto/dpr_auto,w_auto,q_auto,f_auto/v1572218441/upsolar-cl/landing-house-5B_pvn3h8.jpg';

const BannerWrapper = styled.section`
  position: relative;
  padding-top: 200px;
  padding-bottom: 180px;
  height: 100vh;
  background-color: ${({ theme }) => theme.colors.background};
  background-image: ${({ background }) =>
    background ? `url(${background})` : `url(${BannerPic})`};
  transition: 1s all;
  ${'' /* width: 100%; */}
  ${'' /* animation: ${backgroundSlider} 5000ms linear infinite; */}
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
  &:before {
    content: '';
    display: block;
    height: 100%;
    position: absolute;
    width: 100%;
    top: 0;
    background-color: ${({ theme }) => theme.colors.baseLayer};
  }

  ${'' /* @media (min-width: 1440px) {
    background-size: 130vh;
  } */}
  ${'' /* @media (max-width: 1440px) {
    padding-bottom: 320px;
    background-size: 125vh;
  } */}
  ${
    '' /* @media (max-width: 1200px) {
    padding-top: 150px;
    padding-bottom: 300px;
    background-size: 115vh;
  } */
  }
  @media (max-width: 990px) {
    padding-top: 160px;
    padding-bottom: 200px;
    background-color: rgba(0, 0, 0, 0.25);
    ${'' /* background-size: 100vh; */}
  }
  @media (max-width: 768px) {
    ${'' /* background-image: none; */}
    background-color: rgba(0, 0, 0, 0.4);
  }

  @media only screen and (max-width: 480px) {
    ${'' /* padding-top: 130px; */}
    ${'' /* padding-bottom: 200px; */}
  }
  .row {
    position: relative;
    z-index: 1;
  }
  .button__wrapper {
    margin-top: 40px;
    .reusecore__button {
      border-radius: 6px;
      &.btn-outlined {
        color: #fff;
        padding: 5px 10px;
      }
      &:first-child {
        transition: all 0.3s ease;
        margin-right: 30px;
        @media (max-width: 990px) {
          margin-right: 0;
        }
      }
      &:nth-child(2) {
        padding-left: 0;
        @media (max-width: 1100px) {
          padding-left: 15px;
        }

        @media (max-width: 480px) {
          padding-left: 0;
          padding-top: 15px;
        }
        margin-top: 15px;
        .btn-text {
          font-weight: 700;
          margin-top: 7px;
        }
        .btn-icon {
          margin-top: 6px;
          margin-left: 6px;
        }
      }
      > a {
        font-weight: 700;
        color: #fff;
      }
    }
    .btnWithoutColor {
      margin-right: 15px !important;
      padding: 5px 0px !important;
      .btn-icon {
        svg {
          width: 30px;
          height: 30px;
        }
      }
      .btn-text {
        padding-left: 15px;
        font-size: 15px;
        font-weight: 500 !important;
        font-family: 'Poppins', sans-serif;
      }
      &:hover {
        box-shadow: none !important;
      }
    }
  }
  .feature__block {
    h1,
    h2,
    h3,
    h4,
    h5 {
      font-weight: 400;
      color: #fff;
      letter-spacing: -0.025em;
      line-height: 1.2;
      margin-bottom: 25px;
      @media (max-width: 768px) {
        margin-bottom: 20px;
      }
    }
    h1 {
      font-size: 5.25rem;
      @media (max-width: 1440px) {
        font-size: 4.85rem;
      }
      @media (max-width: 990px) {
        font-size: 4.25rem;
      }
      @media (max-width: 768px) {
        font-size: 3.85rem;
      }
      @media (max-width: 572px) {
        font-size: 3rem;
      }
    }
    p {
      color: #fff;
      font-weight: 400;
      line-height: 30px;
      margin-bottom: 20px;
      max-width: 550px;
      font-size: 24px;
      @media (max-width: 1440px) {
        font-size: 24px;
      }
      @media (max-width: 990px) {
        font-size: 22px;
      }
      @media (max-width: 572px) {
        font-size: 20px;
      }
    }
  }
`;
export const BgImageWrapper = styled.div`
  position: absolute;
  left: 0;
  width: 100%;
  bottom: 0;
  img {
    width: 100%;
    height: auto;
    display: block;
    margin: -2px -1px;
    @media (max-width: 480px) {
      margin: -2px -1px;
    }
  }
`;

export const BackgroundSelector = styled.div`
  position: absolute;
  right: 10px;
  bottom: 15px;
  display: flex;
  button {
    width: 30px;
    height: 8px;
    border-radius: 4px;
    border: none;
    cursor: pointer;
    background-color: ${({ theme }) => theme.colors.background};
    opacity: 0.85;
    margin: 0 5px;
    box-shadow: ${({ theme }) => theme.colors.baseBoxShadow};
    transition: 0.5s all;
    &:hover {
      background-color: ${({ theme }) => theme.colors.background};
      opacity: 1;
      transform: translateY(-2px);
    }
    &.active {
      background-color: ${({ theme }) => theme.colors.primary};
      transform: translateY(-2px);
      opacity: 1;
    }
  }
`;

export const ImageLoader = styled.div`
  display: none;
`;
export default BannerWrapper;
