import React, { Fragment } from 'react';
import { Link } from 'gatsby';
import Image from 'reusecore/src/elements/Image';
import Container from '../../components/UI/Container';
import ResourcesBannerWrapper, { GridContainer, ResourceWrapper } from './resourcesBanner.style';

const ResourcesBanner = ({ resources }) => {
  if (resources.length < 1) {
    return null;
  }
  const [first, second, third, ...rest] = resources;
  const featuredResources = [first, second, third];
  const cleanResources = featuredResources.filter(res => res && res);
  const featured = cleanResources[0];
  return (
    <ResourcesBannerWrapper>
      <Container>
        <GridContainer template="70% auto">
          <div className="inner-heading">
            <h3>Recursos</h3>
            <p>Lea alguno de nuestros recursos destacados:</p>
          </div>
          <div className="inner-nav">
            <Link to="/recursos">
              Todos los recursos <i className="flaticon-next" />
            </Link>
          </div>
        </GridContainer>
        {cleanResources.length === 1 ? (
          <GridContainer template="auto">
            <ResourceWrapper featured>
              <div className="resource-img">
                <Image
                  src={featured.frontmatter.cover}
                  alt={`UpSolar ${featured.frontmatter.title}`}
                />
              </div>
              <div className="resource-labels">
                {featured.frontmatter.tags.map((tag, index) => {
                  const isLast = index === featured.frontmatter.tags.length - 1;
                  return (
                    <Fragment key={`${featured.frontmatter.slug}-${tag}`}>
                      <span>{tag}</span>
                      {!isLast && <span className="divider">|</span>}
                    </Fragment>
                  );
                })}
              </div>
              <div className="resource-info">
                <Link to={`/recursos/${featured.frontmatter.slug}`}>
                  <h4>{featured.frontmatter.title}</h4>
                </Link>
                <p>{featured.excerpt}</p>
              </div>
            </ResourceWrapper>
          </GridContainer>
        ) : (
          <GridContainer template={['2fr 1fr 1fr', '1fr 1fr']}>
            {cleanResources.map((solution, i) => {
              const isFirst = i === 0;
              const {
                excerpt,
                frontmatter: { slug, title, cover, tags }
              } = solution;
              return (
                <ResourceWrapper featured={isFirst} key={`${slug}-${title}`}>
                  {isFirst && (
                    <div className="resource-img">
                      <Image src={cover} alt={`UpSolar ${title}`} />
                    </div>
                  )}
                  <div className="resource-labels">
                    {tags.map((tag, index) => {
                      const isLast = index === tags.length - 1;
                      return (
                        <Fragment key={`${slug}-${tag}`}>
                          <span>{tag}</span>
                          {!isLast && <span className="divider">|</span>}
                        </Fragment>
                      );
                    })}
                  </div>
                  <div className="resource-info">
                    <Link to={`/recursos/${slug}`}>
                      <h4>{title}</h4>
                    </Link>
                    <p>{excerpt}</p>
                  </div>
                </ResourceWrapper>
              );
            })}
          </GridContainer>
        )}
      </Container>
    </ResourcesBannerWrapper>
  );
};

export default ResourcesBanner;
