import styled from 'styled-components';

const FeaturedProductsWrapper = styled.div`
  padding-top: 2rem;
`;

export const ProductItemWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 4rem;
  .img-box {
    width: 50%;
    min-height: 45vh;
    max-height: 75vh;
    img {
      ${'' /* padding: 1.5rem; */}
      height: 100%;
      width: 100%;
      display: block;
      object-fit: contain;
      border: 0;
    }
    @media (max-width: 768px) {
      width: 100%;
      max-height: 450px;
    }
  }
  .detail-box {
    align-items: center;
    display: flex;
    width: 50%;
    @media (max-width: 768px) {
      width: 100%;
    }
    min-height: 40vh;
    ${'' /* .container {
      padding: 5rem 10rem;
      @media only screen and (max-width: 991px) {
        padding: 2rem 5rem;
      }
      @media only screen and (max-width: 667px) {
        padding: 2rem;
      }
    } */}
    .detail-title {
      color: ${({ theme }) => theme.colors.headingColor};
      margin: 1.5rem 0;
      font-size: 2.5rem;
      font-weight: 300;
    }
    .detail-body {
      color: ${({ theme }) => theme.colors.labelColor};
      font-size: 1rem;
      margin-top: 20px;
      line-height: 1.6;
    }
  }
  .product-tags {
    margin-bottom: 5px;
    color: ${({ theme }) => theme.colors.labelColor};
    span {
      font-size: 0.8rem;
      line-height: 15px;
      font-weight: 600;
      text-transform: uppercase;
      &.divider {
        margin: 0 6px;
      }
      &.secondary {
        color: ${({ theme }) => theme.colors.orange};
      }
    }
  }
  .btn-outlined {
    color: ${({ theme }) => theme.colors.lightBlue};
    span {
      padding: 0;
    }
    .btn-icon {
      margin-left: 2px;
      transition: 0.2s all;
    }
    &:hover {
      .btn-icon {
        margin-left: 5px;
      }
    }
  }
`;

export default FeaturedProductsWrapper;
