import React, { Fragment } from 'react';
import { Link } from 'gatsby';
import Box from 'reusecore/src/elements/Box';
import Button from 'reusecore/src/elements/Button';
import Image from 'reusecore/src/elements/Image';
import Container from '../../components/UI/Container';
import FeaturedProductsWrapper, { ProductItemWrapper } from './featuredProducts.style';

const isOdd = n => {
  return Math.abs(n % 2) === 1;
};

const FeaturedProductBanner = ({ products = [] }) => {
  if (products.length < 1) {
    return null;
  }
  return (
    <FeaturedProductsWrapper>
      <Container>
        {products.map((product, index) => {
          const isEven = !isOdd(index);
          const {
            frontmatter: { title, description, cover, tags, slug }
          } = product;
          return (
            <ProductItemWrapper key={`key-${title}-${cover}`}>
              {!isEven && (
                <Box className="img-box">
                  <Image src={cover} alt={`SolarPower ${title}`} />
                </Box>
              )}
              <Box className="detail-box">
                <Container>
                  <div className="product-tags">
                    {tags.map((tag, i) => {
                      const isLast = i === tags.length - 1;
                      return (
                        <Fragment key={`${slug}-${tag}`}>
                          <span>{tag}</span>
                          {!isLast && <span className="divider">|</span>}
                        </Fragment>
                      );
                    })}
                  </div>
                  <h3 className="detail-title">{title}</h3>
                  <p className="detail-body">{description}</p>
                  <Link to={`/product/${slug}`}>
                    <Button
                      title="Ver más detalles"
                      variant="textButton"
                      icon={<i className="flaticon-next" />}
                      className="btn-outlined"
                    />
                  </Link>
                </Container>
              </Box>
              {isEven && (
                <Box className="img-box">
                  <Image src={cover} alt={`SolarPower ${title}`} />
                </Box>
              )}
            </ProductItemWrapper>
          );
        })}
      </Container>
    </FeaturedProductsWrapper>
  );
};
export default FeaturedProductBanner;
