import React, { Fragment } from 'react';
import { graphql } from 'gatsby';
import { ThemeProvider } from 'styled-components';
import { ResetCSS } from 'common/source/assets/css/style';
import { theme } from 'common/source/styles/theme';
import { GlobalStyle, ContentWrapper } from 'common/source/styles';
import Navbar from 'common/source/containers/SolarNavbar';

import Banner from 'common/source/containers/BannerSection';
import CategoryGrid from 'common/source/containers/SPCategoryGrid';
import SolutionsBanner from 'common/source/containers/SolutionsBanner';
import FeaturedProductsBanner from 'common/source/containers/FeaturedProductsBanner';
import ResourcesBanner from 'common/source/containers/ResourcesBanner';
// import BlogList from 'common/source/containers/BlogList';
import ContactSection from 'common/source/containers/Contact';

import Footer from 'common/source/containers/Footer';

import SEO from '../components/SEO';

class HomePage extends React.Component {
  render() {
    const { data } = this.props;
    const { products, resources, solutions } = data;
    const featuredProducts = products.edges.map(edge => edge.node);
    const featuredResources = resources.edges.map(edge => edge.node);
    const featuredSolutions = solutions.edges.map(edge => edge.node);
    return (
      <ThemeProvider theme={theme}>
        <Fragment>
          <SEO />
          <ResetCSS />
          <GlobalStyle />

          <ContentWrapper>
            <Navbar />
            <Banner />
            <CategoryGrid />
            <SolutionsBanner solutions={featuredSolutions} />
            <FeaturedProductsBanner products={featuredProducts} />
            <ResourcesBanner resources={featuredResources} />
            {/* <BlogList posts={[featuredPost]} /> */}
            <ContactSection />
            <Footer />
          </ContentWrapper>
        </Fragment>
      </ThemeProvider>
    );
  }
}

export default HomePage;

/* eslint no-undef: "off" */
export const pageQuery = graphql`
  query HomeQuery {
    products: allMarkdownRemark(
      limit: 5
      filter: {
        frontmatter: { featured: { eq: true }, type: { eq: "PRODUCT" }, published: { eq: true } }
      }
      sort: { order: ASC, fields: [frontmatter___storage] }
    ) {
      edges {
        node {
          fields {
            slug
          }
          excerpt
          timeToRead
          frontmatter {
            sku
            title
            slug
            description
            storage
            catalog
            tags
            featured
            price
            price_ref
            thumbnail
            cover
          }
        }
      }
    }
    resources: allMarkdownRemark(
      limit: 3
      filter: { frontmatter: { featured: { eq: true }, type: { eq: "RESOURCE" } } }
      sort: { order: DESC, fields: [frontmatter___date] }
    ) {
      edges {
        node {
          fields {
            slug
          }
          excerpt
          timeToRead
          frontmatter {
            slug
            title
            cover
            thumbnail
            tags
          }
        }
      }
    }
    solutions: allMarkdownRemark(
      limit: 3
      filter: { frontmatter: { featured: { eq: true }, type: { eq: "SOLUTION" } } }
    ) {
      edges {
        node {
          fields {
            slug
          }
          excerpt
          timeToRead
          frontmatter {
            slug
            title
            cover
            thumbnail
            tags
          }
        }
      }
    }
  }
`;
