import React from 'react';
import { Link } from 'gatsby';
import Button from 'reusecore/src/elements/Button';
import Container from '../../components/UI/Container';
import ProductsBannerWrapper, { GridContainer, ProductWrapper } from './productsbanner.style';

const ProductsBanner = ({ solutions }) => {
  if (solutions.length < 1) {
    return null;
  }
  const [first, second, third] = solutions;
  const featuredSolutions = [first, second, third];
  return (
    <ProductsBannerWrapper>
      <Container>
        <GridContainer template="70% auto">
          <div className="inner-heading">
            <h3>Soluciones</h3>
            <p>Descubre algunas de nuestras soluciones destacadas:</p>
          </div>
          <div className="inner-nav">
            <Link to="/soluciones">
              Todas nuestras soluciones 
              {' '}
              <i className="flaticon-next" />
            </Link>
          </div>
        </GridContainer>
        <GridContainer template={['2fr 1fr 1fr', '1fr 1fr']}>
          {featuredSolutions.map((solution, index) => {
            const isFirst = index === 0;
            const {
              excerpt,
              frontmatter: { slug, title, cover }
            } = solution;
            return (
              <ProductWrapper
                featured={isFirst}
                fullWidth={!isFirst}
                cover={cover}
                key={`${slug}-${title}`}
              >
                <div className="product-info">
                  <Link to={`/soluciones/${slug}`}>
                    <h4>{title}</h4>
                  </Link>
                  <p>{excerpt}</p>
                </div>
                <div className="product-nav">
                  <Link to={`/soluciones/${slug}`}>
                    <Button title="Ver Más" variant="outlined" />
                  </Link>
                </div>
              </ProductWrapper>
            );
          })}
        </GridContainer>
      </Container>
    </ProductsBannerWrapper>
  );
};

export default ProductsBanner;
