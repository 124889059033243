import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import Image from 'reusecore/src/elements/Image';
import Box from 'reusecore/src/elements/Box';
import Text from 'reusecore/src/elements/Text';
import Heading from 'reusecore/src/elements/Heading';
import Button from 'reusecore/src/elements/Button';
import BannerWrapper, { BackgroundSelector, ImageLoader } from './bannerSection.style';

import FeatureBlock from '../../components/FeatureBlock';
import Container from '../../components/UI/Container';

const img1 =
  'https://res.cloudinary.com/chamlabs/image/upload/c_fill,g_auto/dpr_auto,w_auto,q_auto,f_auto/v1572218441/upsolar-cl/landing-house-5B_pvn3h8.jpg';
const img2 =
  'https://res.cloudinary.com/chamlabs/image/upload/c_fill,g_auto/dpr_auto,w_auto,q_auto,f_auto/v1572218857/upsolar-cl/back-01_jor8yf.jpg';
const img3 =
  'https://res.cloudinary.com/chamlabs/image/upload/c_fill,g_auto/dpr_auto,w_auto,q_auto,f_auto/v1572218858/upsolar-cl/back-02_rneqwx.jpg';
const img4 =
  'https://res.cloudinary.com/chamlabs/image/upload/c_fill,g_auto/dpr_auto,w_auto,q_auto,f_auto/v1572224633/upsolar-cl/6-back_uqnxgk.jpg';
const img5 =
  'https://res.cloudinary.com/chamlabs/image/upload/c_fill,g_auto/dpr_auto,w_auto,q_auto,f_auto/v1572224634/upsolar-cl/ps-2-back_jzvgdd.jpg';
const images = [img1, img2, img3, img4, img5];

const BannerSection = ({ row, col }) => {
  const [ticks, setTicks] = React.useState(0);
  React.useEffect(() => {
    const interval = setInterval(() => {
      const shouldIncrement = ticks < images.length - 1;
      if (shouldIncrement) {
        setTicks(tick => tick + 1);
      } else {
        setTicks(0);
      }
    }, 10000);
    return () => clearInterval(interval);
  }, [ticks]);

  const ButtonGroup = () => (
    <Fragment>
      <Link to="/soluciones">
        <Button variant="hoverShadow" title="Nuestras Soluciones" />
      </Link>
      <Link to="/catalogo">
        <Button
          title="Catálogo de Productos"
          variant="textButton"
          icon={<i className="flaticon-next" />}
          className="btn-outlined"
        />
      </Link>
    </Fragment>
  );

  return (
    <BannerWrapper id="banner_section" background={images[ticks]}>
      <Container>
        <Box className="row" {...row}>
          <Box className="col" {...col}>
            <FeatureBlock
              title={<Heading as="h1" content="Energía Solar Autónoma" />}
              description={
                <Fragment>
                  <Text content="Soluciones en energía solar para el presente y el futuro" />
                </Fragment>
              }
              button={<ButtonGroup />}
            />
          </Box>
        </Box>
      </Container>
      <BackgroundSelector>
        {images.map((image, index) => {
          return (
            <button
              onClick={() => setTicks(index)}
              aria-label={`background-${index}`}
              key={image}
              className={ticks === index ? `active` : ''}
            ></button>
          );
        })}
      </BackgroundSelector>
      <ImageLoader>
        {images.map(image => (
          <Image src={image} key={image} alt="upsolar image background" />
        ))}
      </ImageLoader>
    </BannerWrapper>
  );
};

BannerSection.propTypes = {
  row: PropTypes.object,
  col: PropTypes.object
};

BannerSection.defaultProps = {
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    ml: '-15px',
    mr: '-15px',
    alignItems: 'center'
  },
  col: {
    pr: '15px',
    pl: '15px',
    width: ['100%', '100%', '100%', '60%', '65%']
  }
};

export default BannerSection;
