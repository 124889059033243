import React from 'react';
import { Link } from 'gatsby';
import Button from 'reusecore/src/elements/Button';
import CategoryGridWrapper, {
  GridContainer,
  CategoryWrapper
} from './categorygrid.style';

const SolutionCiudad =
  'https://res.cloudinary.com/chamlabs/image/upload/f_auto,q_auto/v1568426640/solar-power-cl/soluciones/categorias/transporte-publico-01_web_hf94t1.jpg';
const SolutionRural =
  'https://res.cloudinary.com/chamlabs/image/upload/f_auto,q_auto/v1573663989/upsolar-cl/solutions/solar-835894_1920_vrk3zm.jpg';
const SolutionIndustria =
  'https://res.cloudinary.com/chamlabs/image/upload/f_auto,q_auto/v1573667291/upsolar-cl/solutions/engineer-2558705_1920_yltbi8.jpg';
const SolutionUtility =
  'https://res.cloudinary.com/chamlabs/image/upload/f_auto,q_auto/v1573669486/upsolar-cl/solutions/search-and-rescue-helicopter-4532368_1920_pjl2lr.jpg';
const SolutionParticular =
  'https://res.cloudinary.com/chamlabs/image/upload/f_auto,q_auto/v1568425729/solar-power-cl/soluciones/categorias/house-699978_web_plybqv.jpg';

const CategoryGrid = () => {
  return (
    <CategoryGridWrapper>
      <GridContainer template='60% auto'>
        <CategoryWrapper height='80vh' cover={SolutionRural}>
          <h2>Para uso Rural</h2>
          <p>
            Soluciones portátiles y de fácil instalación para uso en lugares con
            poco o sin acceso a energía eléctrica convencional, como
            parcelaciones, condominios, soluciones agrícolas y aplicaciones
            similares.
          </p>
          <Link to='/rural'>
            <Button title='Revisar Soluciones' variant='outlined' />
          </Link>
        </CategoryWrapper>
        <GridContainer row>
          <CategoryWrapper cover={SolutionParticular}>
            <h2>Para uso Particular</h2>
            <p>
              Soluciones especialmente dirigidas al uso particular. Acerca la
              tecnología solar a tu hogar y a tu estilo de vida!
            </p>
            <Link to='/particular'>
              <Button title='Revisar Soluciones' variant='outlined' />
            </Link>
          </CategoryWrapper>
          <CategoryWrapper cover={SolutionUtility}>
            <h2>Para uso Utilitario</h2>
            <p>
              Soluciones portátiles e integradas de fácil instalación pensadas
              en movilidad y montaje rápido, para llevar un almacenamiento
              eléctrico cargado con energía solar a donde necesites.
            </p>
            <Link to='/utilitario'>
              <Button title='Revisar Soluciones' variant='outlined' />
            </Link>
          </CategoryWrapper>
        </GridContainer>
      </GridContainer>
      <GridContainer>
        <CategoryWrapper height='70vh' cover={SolutionIndustria}>
          <h2>Para uso Industrial</h2>
          <p>
            Soluciones de almacenamiento y generación energética solar autónoma
            de gran potencia para uso industrial, con aplicaciones en
            telecomunicaciones y múltiples industrias.
          </p>
          <Link to='/industrial'>
            <Button title='Revisar Soluciones' variant='outlined' />
          </Link>
        </CategoryWrapper>
      </GridContainer>
    </CategoryGridWrapper>
  );
};

export default CategoryGrid;
