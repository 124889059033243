import styled, { css } from 'styled-components';

const ProductsBannerWrapper = styled.div`
  padding-top: 4rem;
  padding-bottom: 2rem;
  .inner-heading {
    h3 {
      color: ${({ theme }) => theme.colors.headingColor};
      font-size: 30px;
      line-height: 35px;
      font-weight: 900;
      margin: 0;
      margin-bottom: 5px;
    }
    p {
      color: ${({ theme }) => theme.colors.labelColor};
      margin-bottom: 10px;
      font-size: 15px;
      line-height: 20px;
      font-weight: 300;
    }
  }
  .inner-nav {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    @media only screen and (max-width: 667px) {
      justify-content: inherit;
    }
    a {
      color: ${({ theme }) => theme.colors.headingColor};
      text-decoration: none;
      font-weight: 900;
      &:hover {
        text-decoration: underline;
      }
    }
  }
`;

export const GridContainer = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 1fr;
  grid-gap: 20px;
  margin-top: 20px;
  ${({ template }) => {
    if (typeof template === 'string') {
      return css`
        grid-template-columns: ${template};
      `;
    }
    return css`
      grid-template-columns: ${template[0]};
      @media only screen and (min-width: 668px) and (max-width: 980px) {
        grid-template-columns: ${template[1]};
        grid-template-rows: ${template[1]};
      }
    `;
  }}}
  ${({ row }) =>
    row &&
    css`
      margin-top: 0px;
      grid-auto-flow: row;
    `}
  @media only screen and (max-width: 667px) {
    grid-template-columns: 1fr;
    grid-auto-flow: row;
  }
`;

export const ProductWrapper = styled.div`
  display: grid;
  position: relative;
  padding: 3.75rem 1.875rem;
  min-height: 60vh;
  height: 100%;
  border-radius: 3px;
  background: ${({ theme }) => theme.colors.primary};
  color: white;
  z-index: 1;
  ${({ featured }) =>
    featured &&
    css`
      @media only screen and (min-width: 668px) and (max-width: 980px) {
        grid-column-start: 1;
        grid-column-end: 3;
      }
    `}

  .product-info {
    max-width: 65%;
    ${({ fullWidth }) =>
      fullWidth &&
      css`
        max-width: 100%;
      `}
    h4 {
      color: ${({ theme }) => theme.colors.white};
      font-size: 24px;
      line-height: 28px;
      font-weight: 900;
      margin: 0;
      margin-bottom: 5px;
      ${({ featured }) =>
        featured &&
        css`
          font-size: 30px;
          line-height: 35px;
        `}
    }
    p {
      color: ${({ theme }) => theme.colors.white};
      margin-bottom: 10px;
      font-size: 1rem;
      line-height: 20px;
      font-weight: 300;
    }
    @media only screen and (max-width: 667px) {
      max-width: 100%;
      margin-top: 20px;
    }
  }
  .product-nav {
    display: flex;
    align-items: flex-end;
  }
  button {
    transition: 0.2s all;
    font-size: 0.85rem;
    &:hover {
      background-color: #fff;
      border-color: #fff;
      color: ${({ theme }) => theme.colors.textColor};
      box-shadow: ${({ theme }) => theme.colors.baseBoxShadow};
    }
  }

  ${({ height }) =>
    height &&
    css`
      min-height: ${height};
    `}
  ${({ cover }) =>
    cover &&
    css`
      background-image: url(${cover});
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      &:before {
        content: '';
        display: block;
        height: 100%;
        position: absolute;
        width: 100%;
        background-color: ${({ theme }) => theme.colors.normalLayer};
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: -1;
        border-radius: 5px;
        transition: 0.3s all;
      }
      &:hover {
        :before {
          background-color: ${({ theme }) => theme.colors.lightBlueLayer};
        }
        button {
          background-color: #fff;
          border-color: #fff;
          color: ${({ theme }) => theme.colors.textColor};
        }
    `}
`;

export default ProductsBannerWrapper;
